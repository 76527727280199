import { Show, For } from "solid-js";

export default function ValidationErrors(props: {
  field: string;
  errors: string[];
  withPrependIcon?: boolean;
}) {
  return (
    <Show when={props.errors.length}>
      <ul
        id={`err-${props.field}`}
        class="form-error"
        classList={{ "with-prepend-icon": props.withPrependIcon }}
      >
        <For each={props.errors}>
          {(error) => (
            <li class="error" role="alert">
              {error}
            </li>
          )}
        </For>
      </ul>
    </Show>
  );
}
